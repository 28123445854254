.back {
    &-icon {
        width: 80px;
        height: 70px;
        background: url('../../images/game/backPage.png') center center
            no-repeat;
        background-size: 80px 70px;
        margin-left: 100px;
        cursor: pointer;
    }
}
