.game-end {
    width: 100%;
    height: 100%;
    background-color: rgb(239, 246, 249);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .choose {
        padding-top: 80px;
        margin-bottom: 50px;
    }

    .end-container {
        width: 1000px;
        height: 500px;
        background: url('../../images/game/border-blue.png') center center
            no-repeat;
        background-size: 1000px 500px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 60px 120px;
        &-item {
            width: 200px;
            height: 200px;
            background: url('../../images/game/border-ok.png') center center
                no-repeat;
            background-size: 200px 180px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 140px;
                height: 140px;
                margin-left: -30px;
                margin-top: 25px;
            }

            &-word {
                width: 140px;
                height: 140px;
                font-size: 100px;
                font-weight: bolder;
                margin-top: 15px;
                margin-bottom: 10px;
                margin-left: -25px;
                text-align: center;
            }

            &-star {
                width: 150px;
                height: 40px;
                // background-color: yellowgreen;
                display: flex;
                justify-content: center;
                margin-top: -20px;
                margin-left: -20px;
                &-item {
                    width: 40px;
                    height: 40px;
                    background: url('../../images/game/star.png') center center
                        no-repeat;
                    background-size: 40px 40px;
                }
            }
        }
    }

    .end-double {
        width: 1300px;
        height: 500px;
        margin-top: -50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-part {
            width: 660px;
            height: 500px;
            background: url('../../images/game/border-blue.png') center center
                no-repeat;
            background-size: 660px 500px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 60px 10px;
            padding-left: 40px;
            box-sizing: border-box;

            &-yellow {
                background: url('../../images/game/border-yellow.png') center
                    center no-repeat;
                background-size: 660px 500px;
            }
        }

        &-item {
            width: 200px;
            height: 200px;
            background: url('../../images/game/border-ok.png') center center
                no-repeat;
            background-size: 200px 180px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 140px;
                height: 140px;
                margin-top: 20px;
                margin-left: -40px;
            }

            span {
                font-size: 100px;
                font-weight: bolder;
                margin-top: -15px;
                margin-left: -25px;
            }

            .end-container-item-star {
                margin-top: -30px;
            }
        }
    }

    .end-btns {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: transparent;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 30px;
            font-weight: bolder;
            cursor: pointer;

            &-left {
                margin-left: 100px;
            }

            &-again {
                width: 150px;
                height: 150px;
                background: url('../../images/game/again.png') center center
                    no-repeat;
                background-size: 150px 150px;
            }

            &-end {
                width: 150px;
                height: 150px;
                background: url('../../images/game/back.png') center center
                    no-repeat;
                background-size: 115px 115px;
            }

            &-word {
                margin-top: -20px;
            }
        }
    }
}

.cheer-small-one {
    width: 1200px;
    height: 800px;
    background: url('../../images/game/gif/finish-small-one.gif') center center
        no-repeat;
    background-size: 1200px 600px;
    margin-top: -600px;
    margin-left: calc(50% - 600px);
    z-index: 9999999999999999;
}

.cheer-small-one {
    width: 1200px;
    height: 800px;
    background: url('../../images/game/gif/finish-small-double.gif') center
        center no-repeat;
    background-size: 1200px 600px;
    margin-top: -600px;
    margin-left: calc(50% - 900px);
    z-index: 9999999999999999;
}
