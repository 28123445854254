.choose-children {
    width: 100%;
    height: 100%;
    background: url('../../images/game/choose-bg.png') center center no-repeat;
    background-size: 100% 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .children-title {
        width: 100%;
        text-align: center;
        font-size: 40px;
        font-weight: bolder;
        margin-top: 70px;
    }

    .children-container {
        width: 80%;
        height: calc(100vh - 350px);
        overflow-y: auto;
        // display: flex;
        // justify-content: space-between;
        margin-top: 60px;
        flex-wrap: wrap;

        .children-item {
            float: left;
            width: 240px;
            height: 336px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 30px;
            margin-bottom: 40px;
            cursor: pointer;

            &-icon {
                width: 200px;
                height: 336px;
                border: 4px solid white;
                background-color: rgb(192, 214, 225);

                display: flex;
                justify-content: center;
                align-items: center;

                &-img {
                    width: 180px;
                    height: 260px;
                }
            }

            &-word {
                width: 150px;
                height: 40px;
                border: 4px solid white;
                border-radius: 10px;
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bolder;
                font-size: 18px;
                background-color: rgb(192, 214, 225);
            }
        }
    }

    .back {
        width: 100%;
    }
}

.filter-grey {
    filter: grayscale(1);
}
