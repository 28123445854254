.tablet-finish {
    width: 100%;
    height: 100%;
    background: url('../../images/tablet/tablet-login.png') center center
        no-repeat;
    background-size: 100% 100%;

    &-img {
        top: -100%;
        width: 1400px;
        height: 750px;
        text-align: center;
        font-size: 55px;
        font-weight: bolder;
        padding-top: 230px;
    }
}
