.game {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgb(239, 246, 249);
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            width: 700px;
            height: 150px;
            font-size: 35px;
            font-weight: bolder;
            text-align: center;
            background-color: rgb(195, 217, 228);
            background: url('../../images/game/game-title.png') center center
                no-repeat;
            background-size: 700px 150px;
            padding: 0 40px;
            padding-bottom: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-border {
            width: 1000px;
            height: 750px;
            background-color: rgb(195, 217, 228);
            background: url('../../images/game/border-game.png') center center
                no-repeat;
            background-size: 1000px 750px;
            display: flex;
            justify-content: center;
            align-items: center;

            &-icon {
                width: 700px;
                height: 650px;
                background-color: white;
                background: url('../../images/game/border-action.png') center
                    center no-repeat;
                background-size: 700px 650px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 240px;
                font-weight: bolder;
                margin-left: 30px;

                &-finish {
                    background: url('../../images/game/border-ok.png') center
                        center no-repeat;
                    background-size: 700px 650px;
                }

                &-img {
                    width: 480px;
                    height: 480px;
                    margin-left: -80px;
                }

                span {
                    margin-left: -60px;
                }
            }
        }
    }

    &-time {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 90%;
        font-size: 30px;
        font-weight: bolder;

        &-icon {
            width: 50px;
            height: 50px;
            background: url('../../images/game/timeout.png') center center
                no-repeat;
            background-size: 50px 50px;
        }
    }
}
