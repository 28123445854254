.leaderBoard {
    width: 100%;
    height: 100%;
    background-color: rgb(240, 244, 251);
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .title {
        width: 100%;
        height: 130px;
        text-align: center;
        font-size: 30px;
        padding-top: 80px;
        background: url('../../images/game/leader-title.png') center center
            no-repeat;
        background-size: 800px 130px;
    }

    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        margin-top: -70px;

        .leader-class {
            width: 400px;
            height: 630px;
            padding: 140px 0 0 60px;
            font-weight: bolder;
            color: rgb(31, 57, 83);

            .leader-item {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                // width: 280px;
                font-size: 20px;

                &-star {
                    width: 20px !important;
                    height: 20px !important;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }

                img {
                    width: 30px;
                    height: 30px;
                    margin-left: 3px;
                    margin-right: 5px;
                }
            }
        }

        .leader-class-small {
            padding-top: 200px;
            padding-left: 110px;

            background: url('../../images/game/small-class.png') center center
                no-repeat;
            background-size: 400px 630px;
        }
        .leader-class-middle {
            padding-top: 200px;
            padding-left: 110px;

            background: url('../../images/game/middle-class.png') center center
                no-repeat;
            background-size: 400px 630px;
        }
        .leader-class-large {
            padding-top: 200px;
            padding-left: 80px;

            background: url('../../images/game/large-class.png') center center
                no-repeat;
            background-size: 400px 630px;
        }

        .leader-left {
            margin-left: 0px;
        }
    }

    .btn {
        width: 100%;
        display: flex;
        justify-content: center;

        &-start {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 300px;
            height: 100px;
            text-align: center;
            background-color: sandybrown;
            background: url('../../images/game/leader-btn.png') center center
                no-repeat;
            background-size: 300px 100px;
            font-size: 25px;
            color: white;
            font-weight: bolder;
            cursor: pointer;
        }
    }

    .back-icon {
        justify-self: flex-start;
        width: 80px;
        height: 70px;
        background: url('../../images/game/backPage.png') center center
            no-repeat;
        background-size: 70px 80px;
        margin-left: 170px;
        cursor: pointer;
    }
}
