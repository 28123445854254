.level {
    width: 100%;
    height: 100%;
    background-color: rgb(244, 222, 212);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-img {
        top: -100%;
        width: 1900px;
        height: 1000px;
        background: url('../../images/game/level-bg.png') center center
            no-repeat;
        background-size: 1900px 1000px;

        &-1 {
            background: url('http://www.ctwlab.com/images/levels/level-1.png')
                center center no-repeat;
            background-size: 1800px 1000px;
        }

        &-2 {
            background: url('http://www.ctwlab.com/images/levels/level-2.png')
                center center no-repeat;
            background-size: 1400px 800px;
        }

        &-3 {
            background: url('http://www.ctwlab.com/images/levels/level-3.png')
                center center no-repeat;
            background-size: 1400px 800px;
        }

        &-4 {
            background: url('http://www.ctwlab.com/images/levels/level-4.png')
                center center no-repeat;
            background-size: 1400px 800px;
        }

        &-5 {
            background: url('http://www.ctwlab.com/images/levels/level-5.png')
                center center no-repeat;
            background-size: 1400px 800px;
        }

        &-6 {
            background: url('http://www.ctwlab.com/images/levels/level-6.png')
                center center no-repeat;
            background-size: 1400px 800px;
        }
    }

    &-item {
        float: left;
        width: 140px;
        height: 90px;
        // background-color: red;
        opacity: 0.8;
        cursor: pointer;

        &-1 {
            margin-top: 640px;
            margin-left: 240px;
        }

        &-2 {
            margin-top: 520px;
            margin-left: 150px;
        }

        &-3 {
            margin-top: 300px;
            margin-left: 100px;
        }

        &-4 {
            margin-top: 490px;
            margin-left: 220px;
        }

        &-5 {
            margin-top: 710px;
            margin-left: 20px;
        }

        &-6 {
            margin-top: 370px;
            margin-left: 190px;
        }
    }

    .back {
        margin-left: -1800px;
        margin-top: -100px;
    }
}
