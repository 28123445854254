.detail {
    &-bg {
        width: 100%;
        height: 120px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: 900;
        padding: 0 60px;

        .choose {
            width: unset;
            padding-top: 0;
            margin-bottom: 0;
        }

        &-tabs {
            width: 470px;
            height: 35px;
            display: flex;
            justify-content: space-between;

            &-item {
                width: 100px;
                height: 35px;
                background-color: rgb(106, 85, 145);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                color: white;
                cursor: pointer;
            }
        }
    }
}
