.choose-mode {
    width: 100%;
    height: 100%;
    background-color: rgb(239, 246, 249);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .choose {
        width: 85%;
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        margin-top: 40px;
        margin-bottom: 80px;
        color: white;
        font-weight: bolder;

        &-item {
            min-width: 180px;
            height: 30px;
            background-color: rgb(187, 193, 196);
            text-align: center;
            border-radius: 15px;
        }
    }
    .selection {
        width: 1200px;
        height: 580px;
        background-color: rgb(215, 234, 245);
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        &-title {
            display: flex;
            justify-content: center;
            writing-mode: vertical-rl;
            background-color: rgb(31, 57, 83);
            color: white;
            font-size: 40px;
            font-weight: bolder;
            margin-top: 10px;
            margin-left: -160px;
            border-radius: 10px;

            align-items: center;
            width: 120px;
            height: 320px;
            font-weight: bolder;
            margin-top: 10px;
            border: 30px solid #dbe9f4;
        }

        &-item {
            width: 400px;
            height: 500px;
            border: 4px solid white;
            border-bottom-width: 160px;
            border-radius: 30px;
            background-color: white;
            cursor: pointer;

            &-title {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bolder;
                font-size: 60px;
                margin-top: 20px;
                color: white;
            }
        }
        &-base {
            border-color: rgb(239, 185, 112);
            &-icon {
                width: 400px;
                height: 350px;
                background: url('../../images/game/gif/play-free.gif') center
                    center no-repeat;
                background-size: 350px 320px;
                margin-left: 20px;
            }
        }
        &-interest {
            border-color: rgb(152, 184, 211);

            &-icon {
                width: 400px;
                height: 350px;
                background: url('../../images/game/gif/play-level.gif') center
                    center no-repeat;
                background-size: 300px 320px;
            }
        }
    }
    .back {
        align-self: flex-start;
        margin-top: 50px;
        &-icon {
            width: 55px;
            height: 50px;
            background: url('../../images/game/backPage.png') center center
                no-repeat;
            background-size: 55px 50px;
            margin-left: 100px;
            cursor: pointer;
        }
    }
}
