.tablet-child {
    width: 100%;
    height: 100%;
    background: url('../../images/tablet/tablet-child.png') center center
        no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .title {
        font-size: 40px;
        font-weight: bolder;
        width: 100%;
        text-align: center;
        color: rgb(32, 58, 80);
        padding-top: 40px;
    }

    .choosing {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 175px;
            height: 300px;
            cursor: pointer;

            &-a {
                background: url('../../images/game/select-1.png') center center
                    no-repeat;
                background-size: 175px 300px;
            }

            &-img {
                width: 150px;
                height: 210px;
                margin-top: 60px;
            }

            &-desc {
                width: 320px;
                height: 50px;
                margin-top: 10px;
                // background-color: rgb(192, 214, 225);
                color: rgb(31, 55, 80);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                font-weight: bolder;

                &-student {
                    margin-left: 10px;
                }
            }
        }
    }
    .btn {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: -30px;
        &-icon-gray {
            width: 80px;
            height: 80px;
            background: url('../../images/game/start-game-gray.png') center
                center no-repeat;
            background-size: 80px 80px;
        }

        &-icon {
            width: 80px;
            height: 80px;
            background: url('../../images/game/start-game.png') center center
                no-repeat;
            background-size: 80px 80px;
        }

        &-word {
            font-size: 28px;
            font-weight: bolder;
            color: rgb(32, 57, 83);
            margin-top: -10px;
        }
    }
    .back {
        &-icon {
            width: 55px;
            height: 50px;
            background: url('../../images/game/backPage.png') center center
                no-repeat;
            background-size: 55px 50px;
            margin-left: 170px;
            cursor: pointer;
        }
    }
}
