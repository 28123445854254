.profile {
    width: 100%;
    height: 100%;
    // margin-top: -150px;
    background-size: 100% 100%;
    padding-left: 50px;

    .choose {
        padding-top: 80px;
    }

    &-medal {
        width: 200px;
        height: 200px;
        // background-color: greenyellow;
        float: right;
        margin-right: 50px;
        margin-top: -80px;
        cursor: pointer;
    }

    .back-icon {
        justify-self: flex-start;
        width: 80px;
        height: 70px;
        background: url('../../images/game/backPage.png') center center
            no-repeat;
        background-size: 80px 70px;
        margin-left: 50px;
        cursor: pointer;
        position: fixed;
        bottom: 40px;
    }
}
