.choose-action {
    width: 100%;
    height: 100%;
    background-color: rgb(239, 246, 249);
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .choosing {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        padding-top: 40px;
        &-block {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 400px;
            height: 700px;
            cursor: pointer;

            &-a {
                background: url('../../images/game/select-1.png') center center
                    no-repeat;
                background-size: 400px 700px;
            }

            &-b {
                background: url('../../images/game/select-2.png') center center
                    no-repeat;
                background-size: 400px 700px;
            }

            &-default {
                width: 400px;
                height: 400px;
                background-color: aqua;
            }

            &-img {
                margin-left: 5px;
                width: 360px;
                height: 504px;
                margin-top: 140px;
            }

            &-desc {
                width: 260px;
                color: rgb(31, 55, 80);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-size: 35px;
                font-weight: bolder;

                &-student {
                    margin-left: 10px;
                }
            }

            &-click {
                width: 200px;
                height: 200px;
                margin-left: -350px;
                margin-top: -150px;
            }
        }
    }
    .btn {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -20px;
        cursor: pointer;

        &-icon-gray {
            width: 120px;
            height: 120px;
            background: url('../../images/game/start-game-gray.png') center
                center no-repeat;
            background-size: 120px 120px;
        }

        &-icon {
            width: 120px;
            height: 120px;
            background: url('../../images/game/start-game.png') center center
                no-repeat;
            background-size: 120px 120px;
        }

        &-word {
            font-size: 28px;
            font-weight: bolder;
            color: rgb(32, 57, 83);
            margin-top: -10px;
        }
    }
    .back {
        margin-top: -40px;
        &-icon {
            width: 80px;
            height: 70px;
            background: url('../../images/game/backPage.png') center center
                no-repeat;
            background-size: 80px 70px;
            margin-left: 70px;
            cursor: pointer;
        }
    }
}
