.check-point {
    width: 100%;
    height: 100%;
    background: url('../../images/game/checkPoint.png') center center no-repeat;
    background-size: 100% 100%;
    filter: blur(10px);

    &-bg {
        width: 100%;
        height: 100%;
        background: url('../../images/game/checkPoint.png') center center
            no-repeat;
        background-size: 1350px 750px;
        filter: blur(0px);
        margin-top: -770px;
    }
}
