.choose-class {
    width: 100%;
    height: 100%;
    background: url('../../images/game/choose-bg.png') center center no-repeat;
    background-size: 100% 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .class-title {
        width: 100%;
        text-align: center;
        font-size: 50px;
        font-weight: bolder;
        margin-top: 70px;
    }

    .class-container {
        width: 66%;
        height: 700px;
        display: flex;
        justify-content: space-between;
        margin-top: 140px;
        overflow: auto;

        .class-group {
            &-item {
                width: 300px;
                height: 100px;
                background-color: rgb(192, 214, 225);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 80px;
                color: rgb(32, 57, 83);
                font-size: 35px;
                font-weight: bolder;
                border: 4px solid white;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }

    .back {
        width: 100%;
    }
}
