.medal {
    width: 100%;
    height: 100%;

    &-header {
        width: 100%;
        height: 80px;
        background-color: rgb(245, 192, 86);
        display: flex;

        &-person {
            width: 240px;
            height: 80px;
            background-color: rgb(247, 210, 116);

            .choose {
                padding-top: 27px;
                padding-left: 30px;
            }
        }

        &-title {
            line-height: 80px;
            padding-left: calc(50% - 280px);
            font-size: 35px;
            font-weight: bolder;
            color: white;
        }
    }

    &-tab {
        width: 100%;
        height: 80px;
        background-color: rgb(247, 210, 116);
        display: flex;

        &-item {
            width: 33.333%;
            height: 100%;
            line-height: 80px;
            text-align: center;
            color: white;
            font-size: 25px;
            font-weight: bolder;
            padding: 0 120px;
            cursor: pointer;

            &-selected {
                width: 100%;
                height: 4px;
                background-color: white;
                margin-top: -4px;
            }
        }

        &-sports {
            background-color: rgb(242, 166, 86);
        }

        &-fun {
            background-color: rgb(248, 210, 116);
        }

        &-together {
            background-color: rgb(248, 219, 153);
        }
    }

    &-container {
        width: 800px;
        height: 500px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 170px;
        margin-left: calc(50% - 400px);

        &-item {
            width: 220px;
            height: 240px;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &-img {
                width: 200px;
                height: 200px;

                &-unlock {
                    width: 135px;
                    height: 135px;
                    margin-top: 25px;
                    margin-bottom: 40px;
                }
            }

            &-title {
                font-size: 20px;
                font-weight: bolder;
                color: rgb(36, 23, 26);
                margin-top: -20px;

                &-star {
                    width: 25px;
                    height: 25px;
                    margin-top: -4px;
                    margin-left: 5px;
                }
            }
        }
    }

    .back-icon {
        justify-self: flex-start;
        width: 80px;
        height: 70px;
        background: url('../../images/game/backPage.png') center center
            no-repeat;
        background-size: 80px 70px;
        margin-top: 60px;
        margin-left: 100px;
        cursor: pointer;
    }
}
