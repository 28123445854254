.children {
    &-bg {
        width: 100%;
        height: 250px;
        background: url('../../images/children-bg.png') center center no-repeat;
        background-size: 100% auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 900;

        &-title {
            font-size: 40px;
        }

        &-sub {
            font-size: 15px;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        &-input {
            opacity: 0;
            width: 80px;
            height: 80px;
            cursor: pointer;
        }

        &-upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            margin-top: -80px;

            &-icon {
                width: 40px;
                height: 40px;
                background: url('../../images/children-upload.png') center
                    center no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    &-bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-btn {
        width: 150px;
        height: 45px;
        background-color: #153a55;
        color: white;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        border-radius: 5px;
        cursor: pointer;

        &-icon {
            width: 20px;
            height: 20px;
            background: url('../../images/children-save.png') center center
                no-repeat;
            background-size: 20px 20px;
            margin-right: 5px;
        }
    }
}

.detail-container {
    display: flex;
    justify-content: center;
}

.del-btn {
    margin-left: 10px;
}

.detail-btn {
    font-weight: 900;
    cursor: pointer;
}

.remark {
    display: flex;
    align-items: center;
    justify-content: center;
    &-input {
        border-width: 0;
        border-bottom-width: 1px;
        background-color: transparent;
        min-width: 60px;
    }

    &-icon {
        width: 20px;
        height: 20px;
        background: url('../../images/children-edit.png') center center
            no-repeat;
        background-size: 20px 20px;
        margin-left: 10px;
    }
}

.student-img {
    width: 40px;
    height: 40px;
}

.form-footer {
    text-align: right;
}

.ant-input {
    border-width: 0;
}
