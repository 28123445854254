.choose {
    width: 85%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: white;
    font-weight: bolder;
    z-index: 9999;
    cursor: pointer;

    &-item {
        min-width: 180px;
        height: 30px;
        background-color: rgb(187, 193, 196);
        text-align: center;
        border-radius: 15px;
        display: flex;

        img {
            width: 30px;
            height: 30px;
            background-color: rgb(247, 209, 137);
            border-radius: 15px;
            margin-right: 10px;
        }

        &-icon {
            width: 30px;
            height: 30px;
            background: url('../../images/game/user.png') center center
                no-repeat;
            background-size: 40px 32px;
            margin-right: 10px;
        }
    }
}
