.children-selection {
    width: 100%;
    height: 100%;
    background-color: rgb(239, 246, 249);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .choose {
        width: 85%;
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        margin-top: 40px;
        margin-bottom: 80px;
        color: white;
        font-weight: bolder;

        &-item {
            min-width: 180px;
            height: 30px;
            background-color: rgb(187, 193, 196);
            text-align: center;
            border-radius: 15px;
        }
    }
    .selection {
        width: 1600px;
        height: 580px;
        background-color: rgb(215, 234, 245);
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        &-title {
            display: flex;
            justify-content: center;
            align-items: center;
            writing-mode: vertical-rl;
            width: 120px;
            height: 320px;
            background-color: rgb(31, 57, 83);
            color: white;
            font-size: 40px;
            font-weight: bolder;
            margin-top: 10px;
            margin-left: -140px;
            border-radius: 10px;
            border: 30px solid rgb(219, 233, 244);
        }

        &-item {
            width: 400px;
            height: 500px;
            border: 4px solid white;
            border-top-width: 170px;
            border-radius: 50px;
            background-color: white;
            cursor: pointer;

            &-title {
                width: 100%;
                margin-top: -125px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bolder;
                font-size: 60px;
            }
        }
        &-base {
            border-color: rgb(195, 217, 228);
            &-icon {
                width: 400px;
                height: 400px;
                margin-top: -40px;
                background: url('../../images/game/sport.gif') center center
                    no-repeat;
                background-size: 300px 350px;
            }
        }
        &-interest {
            border-color: rgb(248, 213, 214);

            &-icon {
                width: 400px;
                height: 400px;
                margin-top: -40px;
                background: url('../../images/game/fun.gif') center center
                    no-repeat;
                background-size: 400px 350px;
            }
        }
        &-together {
            border-color: rgb(254, 248, 211);

            &-icon {
                width: 400px;
                height: 400px;
                margin-top: -40px;
                background: url('../../images/game/together.gif') center center
                    no-repeat;
                background-size: 400px 350px;
            }
        }
    }
    .back {
        align-self: flex-start;
        margin-top: 50px;
        &-icon {
            width: 55px;
            height: 50px;
            background: url('../../images/game/backPage.png') center center
                no-repeat;
            background-size: 55px 50px;
            margin-left: 100px;
            cursor: pointer;
        }
    }
}
