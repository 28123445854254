@font-face {
  font-family: yuanti;
  src: url('//www.ctwlab.com/images/fontFamily/yuanti.ttf');
}

body {
  margin: 0;
  font-family: yuanti !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: yuanti !important;
}

* {
  padding: 0;
  margin: 0;
}

h1 {
  text-align: center;
  font-size: 45px;
  font-family: yuanti !important;
  color: rgb(6, 0, 32);
  padding: 40px;
}

#root {
  width: 100vw;
  height: 100vh;
}