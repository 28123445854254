.fun {
    width: 100%;
    height: 100%;
    background-color: rgb(239, 246, 249);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .choose {
        width: 85%;
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        margin-top: 40px;
        margin-bottom: 80px;
        color: white;
        font-weight: bolder;

        &-item {
            min-width: 180px;
            height: 30px;
            background-color: rgb(187, 193, 196);
            text-align: center;
            border-radius: 15px;
        }
    }
    .fun-selection {
        width: 1200px;
        height: 600px;
        background-color: rgb(215, 234, 245);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        border-radius: 10px;

        &-item {
            width: 350px;
            height: 500px;
            border: 2px solid white;
            border-bottom-width: 150px;
            border-radius: 10px 10px 0 0;
            background-color: white;
            cursor: pointer;

            &-title {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bolder;
                font-size: 40px;
                margin-top: 45px;
            }
        }
        &-base {
            border-color: rgb(195, 217, 228);
            &-icon {
                width: 300px;
                height: 350px;
                background: url('../../images/game/gif/fun-zoo.gif') center
                    center no-repeat;
                background-size: 300px 350px;
                margin-left: 25px;
            }
        }
        &-interest {
            border-color: rgb(248, 213, 214);

            &-icon {
                width: 300px;
                height: 350px;
                background: url('../../images/game/gif/fun-emo.gif') center
                    center no-repeat;
                background-size: 300px 350px;
                margin-left: 25px;
            }
        }
        &-together {
            border-color: rgb(254, 248, 211);

            &-icon {
                width: 300px;
                height: 350px;
                background: url('../../images/game/gif/fun-gongfu.gif') center
                    center no-repeat;
                background-size: 300px 350px;
                margin-left: 25px;
            }
        }
    }
    .back {
        align-self: flex-start;
        margin-top: 50px;
        &-icon {
            width: 55px;
            height: 50px;
            background: url('../../images/game/backPage.png') center center
                no-repeat;
            background-size: 55px 50px;
            margin-left: 100px;
            cursor: pointer;
        }
    }
}
