.login {
    width: 100%;
    height: 100%;
    background: url('../../images/login-background.png') top left no-repeat;
    background-size: 100% 100%;

    &-green {
        height: 100%;
        background-color: rgba(57, 94, 97, 0.52);
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &-word {
        height: 270px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        &-title {
            font-size: 70px;
            font-weight: 900;
            height: 85px;
        }

        &-sub {
            font-size: 25px;
            margin-top: 30px;
        }
    }

    &-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        width: 350px;
        height: 235px;
        background-color: #b096c5;
        margin-left: 60px;
        margin-right: 80px;

        background: linear-gradient(
            to top,
            #b096c5,
            #b096c5,
            rgb(211, 192, 221)
        );
        input {
            width: calc(100% - 25px);
            height: 50px;
            margin-top: 15px;
            padding: 0 15px;
            box-sizing: border-box;
            font-size: 15px;
            border-width: 0;
        }

        &-btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 100px;
        }

        &-btn {
            // align-self: flex-end;
            color: white;
            font-weight: 900;
            background-color: #6d5594;
            width: 140px;
            height: 60px;
            // margin-top: 20px;
            margin-right: 10px;
            // margin-left: 10px;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &-icon {
                width: 30px;
                height: 30px;
                background: url('../../images/login-btn.png') center center
                    no-repeat;
                background-size: 20px 20px;
                margin-left: -10px;
            }
        }

        &-other {
            width: 70px;
            border-radius: 0;
            background-color: transparent;
        }
    }
}
