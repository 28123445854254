.tablet-star {
    width: 100%;
    height: 100%;
    background-color: rgb(239, 246, 249);
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .title {
        font-size: 40px;
        font-weight: bolder;
        width: 100%;
        height: 200px;
        text-align: center;
        color: rgb(32, 58, 80);
        background: url('../../images/tablet/score-title.png') center center
            no-repeat;
        background-size: 320px 150px;
    }

    .star {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-img {
            // background-color: skyblue;
            border: 4px solid skyblue;
            width: 300px;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 120px;
            font-weight: bolder;
            border-radius: 20px;
            background-color: white;

            img {
                width: 280px;
                height: 280px;
            }
        }

        &-score {
            border: 4px solid skyblue;
            width: 600px;
            height: 300px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            border-radius: 20px;
            background-color: white;

            &-item {
                background-color: orange;
                width: 250px;
                height: 140px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 50px;
                font-weight: bolder;
                color: white;
                cursor: pointer;

                &-one-star {
                    background: url('../../images/tablet/one-star.png') center
                        center no-repeat;
                    background-size: 250px 140px;

                    &-selected {
                        background: url('../../images/tablet/one-star-selected.png')
                            center center no-repeat;
                        background-size: 250px 140px;
                    }
                }

                &-two-stars {
                    background: url('../../images/tablet/two-stars.png') center
                        center no-repeat;
                    background-size: 250px 140px;

                    &-selected {
                        background: url('../../images/tablet/two-stars-selected.png')
                            center center no-repeat;
                        background-size: 250px 140px;
                    }
                }

                &-three-stars {
                    background: url('../../images/tablet/three-stars.png')
                        center center no-repeat;
                    background-size: 250px 140px;

                    &-selected {
                        background: url('../../images/tablet/three-stars-selected.png')
                            center center no-repeat;
                        background-size: 250px 140px;
                    }
                }
            }
        }
    }
    .btn {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 9999;

        &-icon-gray {
            width: 120px;
            height: 120px;
            background: url('../../images/game/start-game-gray.png') center
                center no-repeat;
            background-size: 120px 120px;
        }

        &-icon {
            width: 120px;
            height: 120px;
            background: url('../../images/game/start-game.png') center center
                no-repeat;
            background-size: 120px 120px;
        }

        &-word {
            font-size: 34px;
            font-weight: bolder;
            color: rgb(32, 57, 83);
            margin-top: -10px;
        }
    }
    .back {
        &-icon {
            width: 55px;
            height: 50px;
            background: url('../../images/game/backPage.png') center center
                no-repeat;
            background-size: 55px 50px;
            margin-left: 170px;
            cursor: pointer;
        }
    }

    .game-time {
        position: fixed;
        bottom: 70px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 90%;
        font-size: 30px;
        font-weight: bolder;

        &-icon {
            width: 50px;
            height: 50px;
            background: url('../../images/game/timeout.png') center center
                no-repeat;
            background-size: 50px 50px;
        }
    }
}
