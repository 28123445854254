.nav {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #6d5594;
    color: white;
    padding: 0 30px;
    box-sizing: border-box;
    font-weight: 700;

    &-title {
        font-size: 20px;
        display: flex;
        cursor: pointer;

        &-icon {
            width: 30px;
            height: 30px;
            background: url('../../images/nav-icon.png') center center no-repeat;
            background-size: 30px 30px;
            margin-right: 10px;
        }
    }

    &-link {
        display: flex;
        flex-direction: row;

        &-item {
            margin-left: 15px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &-icon {
                width: 18px;
                height: 18px;
            }

            &-person {
                background: url('../../images/person.png') center center
                    no-repeat;
                background-size: 18px 18px;
            }

            &-download {
                background: url('../../images/download.png') center center
                    no-repeat;
                background-size: 18px 18px;
            }

            &-logout {
                background: url('../../images/logout.png') center center
                    no-repeat;
                background-size: 18px 18px;
                margin-left: 40px;
            }
        }
    }
}

.export-dialog {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &-class {
        &-item {
            width: 100px;
            height: 40px;
            line-height: 40px;
            background-color: rgb(41, 71, 92);
            margin-bottom: 15px;
            color: white;
            text-align: center;
            font-size: 17px;
            font-weight: bolder;
            border-radius: 10px;
            cursor: pointer;
        }
    }
}
