.game-ready {
    width: 100%;
    height: 100%;
    background: url('../../images/game/choose-bg.png') center center no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .ready-title {
        color: rgb(32, 57, 83);
        font-size: 45px;
        font-weight: bolder;
        margin-top: 40px;
    }

    .ready-icon {
        width: 600px;
        height: 750px;
        background: url('../../images/game/clock.png') center center no-repeat;
        background-size: 600px 600px;
        margin-top: 0px;
    }
    .ready-btm {
        width: 100%;
        height: 50px;
    }
}
